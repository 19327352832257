@charset "utf-8";
@import "../../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/components/_conversation-header.scss";
@import "../../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/components/_message.scss";
@import "../../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/components/_message-input.scss";
@import "../../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/components/_buttons.scss";
@import "../../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/components/_typing-indicator.scss";

@media only screen and (max-width: 600px) {
  .MessageInput {
    width: 100%;
    box-sizing: border-box;
  }
}